.contact
  color: white
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url('../images/contact-bg.png')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed

  .row
    align-items: center
    justify-content: center

.contact-header
  padding-bottom: 20px
  text-align: center

.contact-form
  background-color: white
  padding: 42px 42px 20px 42px
  border-radius: 25px

  input
    width: 100%
    height: 50px
    margin-bottom: 18px
    padding: 0 20px
    border-radius: 16px

  textarea
    width: 100%
    min-height: 150px
    margin-bottom: 10px
    padding: 16px
    border-radius: 16px

  .button-body
    display: flex
    justify-content: center

    .btn
      width: 120px
      height: 48px
      font-size: 18px
      border-radius: 16px
      background-color: rgb(30,205,151)
      color: rgb(255, 255, 255)
