.skills
    color: white
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url('../images/skills-bg.jpg')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-attachment: fixed
    overflow: hidden

    .skills-grid
        display: flex
        justify-content: center

        .icon
            font-size: 50px

        .item
            text-align: center
            padding: 30px
