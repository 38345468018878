.about
  overflow-x: hidden

@media screen and (max-width: 992px)
  .about-title
    padding-top: 25px

.about-content
  align-items: center

.profile-picture
  border-radius: 50%
  width: 100%
  box-shadow: 5px 5px 5px rgba(0,0,0,0.7)
