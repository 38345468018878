.loading
  position: fixed
  background: rgb(25, 25, 25)
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  z-index: 10

@-webkit-keyframes cube
  0%
  -webkit-transform: rotate(45deg) rotateX(-25deg) rotateY(25deg)
  transform: rotate(45deg) rotateX(-25deg) rotateY(25deg)

  50%
  -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(25deg)
  transform: rotate(45deg) rotateX(-385deg) rotateY(25deg)

  100%
  -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(385deg)
  transform: rotate(45deg) rotateX(-385deg) rotateY(385deg)

@keyframes cube
  0%
    -webkit-transform: rotate(45deg) rotateX(-25deg) rotateY(25deg)
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg)

  50%
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(25deg)
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg)

  100%
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(385deg)
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg)

.cube
  -webkit-animation: cube 2s infinite ease
  animation: cube 2s infinite ease
  height: 40px
  width: 40px
  display: flex
  justify-content: center
  align-content: center
  -webkit-transform-style: preserve-3d
  transform-style: preserve-3d

  div
    position: absolute
    height: 100%
    width: 100%
    border: 2px solid rgb(255, 255, 255)

    &:nth-of-type(1)
      -webkit-transform: translateZ(-20px) rotateY(180deg)
      transform: translateZ(-20px) rotateY(180deg)

    &:nth-of-type(2)
      -webkit-transform: rotateY(-270deg) translateX(50%)
      transform: rotateY(-270deg) translateX(50%)
      -webkit-transform-origin: top right
      transform-origin: top right

    &:nth-of-type(3)
      -webkit-transform: rotateY(270deg) translateX(-50%)
      transform: rotateY(270deg) translateX(-50%)
      -webkit-transform-origin: center left
      transform-origin: center left

    &:nth-of-type(4)
      -webkit-transform: rotateX(90deg) translateY(-50%)
      transform: rotateX(90deg) translateY(-50%)
      -webkit-transform-origin: top center
      transform-origin: top center

    &:nth-of-type(5)
      -webkit-transform: rotateX(-90deg) translateY(50%)
      transform: rotateX(-90deg) translateY(50%)
      -webkit-transform-origin: bottom center
      transform-origin: bottom center

    &:nth-of-type(6)
      -webkit-transform: translateZ(20px)
      transform: translateZ(20px)
