/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburger
.hamburger
  padding: 15px 15px
  display: inline-block
  cursor: pointer
  transition-property: opacity, filter
  transition-duration: 0.15s
  transition-timing-function: linear
  font: inherit
  color: inherit
  text-transform: none
  background-color: transparent
  border: 0
  margin: 0
  overflow: visible

  &:hover
    opacity: 0.7

  &.is-active
    &:hover
      opacity: 0.7

    .hamburger-inner
      background-color: rgb(255, 255, 255)

      &::before, &::after
        background-color: rgb(255, 255, 255)

.hamburger-box
  width: 40px
  height: 24px
  display: inline-block
  position: relative

.hamburger-inner
  display: block
  top: 50%
  margin-top: -2px
  width: 40px
  height: 4px
  background-color: rgb(255, 255, 255)
  border-radius: 4px
  position: absolute
  transition-property: transform
  transition-duration: 0.15s
  transition-timing-function: ease

  &::before, &::after
    width: 40px
    height: 4px
    background-color: rgb(255, 255, 255)
    border-radius: 4px
    position: absolute
    transition-property: transform
    transition-duration: 0.15s
    transition-timing-function: ease

  &::before, &::after
    content: ""
    display: block

  &::before
    top: -10px

  &::after
    bottom: -10px

/*
 * * Spin
.hamburger--spin
  .hamburger-inner
    transition-duration: 0.22s
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

    &::before
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in

    &::after
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)

  &.is-active .hamburger-inner
    transform: rotate(225deg)
    transition-delay: 0.12s
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

    &::before
      top: 0
      opacity: 0
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out

    &::after
      bottom: 0
      transform: rotate(-90deg)
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
