.navbar
  position: absolute
  width: 100%
  height: 69px
  background: transparent
  z-index: 5

  .container-fluid
    padding: 0px

  .navbar-brand
    color: rgb(255, 255, 255)
    padding: 15px 15px

  .nav-link
    margin-bottom: 8px
    margin-right: 8px
    color: rgb(255, 255, 255)

  .navbar-collapse
    text-align: center

.navbar-scroll
  background: rgba(0, 0, 0, 0.8)
  position: fixed
  top: -100px
  width: 100%
  transition: transform .4s
  transform: translateY(100px)

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus
  outline: none
  box-shadow: none

@media screen and (max-width: 992px)
  .navbar, .navbar-collapse
    background: rgba(0, 0, 0, 0.8) !important

.hover-underline-animation 
  display: inline-block
  position: relative
  color: #fff
  cursor: pointer
  font-size: 20px


.hover-underline-animation:after 
  content: ''
  position: absolute
  width: 100%
  transform: scaleX(0)
  height: 2px
  bottom: 0
  left: 0
  background-color: #fff
  transform-origin: bottom right
  transition: transform 0.25s ease-out


.hover-underline-animation:hover:after 
  transform: scaleX(1)
  transform-origin: bottom left
