.experience
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url('../images/experience-bg.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed
  overflow: hidden

  .row
    justify-content: center

.experience-header
  color: white
  text-align: center