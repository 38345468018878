ul.timeline
  list-style-type: none
  position: relative
  padding-left: 1.5rem
  padding-right: .5rem

li.timeline-item
  margin: 20px 0

/* Timeline item arrow

.timeline-arrow
  border-top: 0.5rem solid transparent
  border-right: 0.5rem solid #fff
  border-bottom: 0.5rem solid transparent
  display: block
  position: absolute
  left: 2rem

/* Timeline vertical line

ul.timeline:before
  content: ' '
  background: rgb(109, 109, 109)
  display: inline-block
  position: absolute
  left: 16px
  width: 4px
  height: 100%
  z-index: 3
  border-radius: 1rem

/* Timeline item circle marker

li.timeline-item::before
  content: ''
  background: #ddd
  position: absolute
  border-radius: 50%
  border: 3px solid #fff
  left: 11px
  width: 14px
  height: 14px
  z-index: 4
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
