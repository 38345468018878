body
  margin: 0
  font-family: 'Nunito', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

section
  padding-top: 100px
  padding-bottom: 100px

.section-header
  text-align: center
  margin-bottom: 50px

.footer
  text-align: center
  color: white
  background: rgb(24, 24, 24)
  padding: 16px 0px
