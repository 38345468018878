.tools
    overflow: hidden

    .tools-grid
        display: flex
        justify-content: center

    .icon
        font-size: 50px

    .item
        text-align: center
        padding: 30px
