.home
  color: white
  min-height: 100.1vh
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/home-bg.png')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed
  display: flex
  justify-content: center
  align-items: center

.home-content
  text-align: center

  h2
    font-weight: 500
    font-size: 40px

  h1
    font-weight: 300
    font-size: 55px

.continue-arrow
  position: absolute
  bottom: 20vh
  animation: updown 2s ease-in-out infinite

  .btn-scroll
    color: white
    font-size: 48px

@keyframes updown
  0%, 100%
    transform: translateY(0%)

  50%
    transform: translateY(48px)
